@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800');
* {
  box-sizing: border-box;
}
body, html, .App, #root {
  width: 100%;
  height: 100%;
}

h1,h2,h3,h4,h5,h6,label,span {
  // font-weight: 500;
  font-family: 'Fira Sans', sans-serif;
}

