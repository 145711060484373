@use '../../App.scss' as *;
@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800');

* {
  box-sizing: border-box;
}

h1,h2,h3,h4,h5,h6,label,span {
  font-weight: 500;
  font-family: 'Fira Sans', sans-serif;
}

.auth-inner {    
    // width: 80%;
    margin: auto;
    background-color: $purpleBase;
    color: $textWhite;  
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    border-radius: 15px;
    transition: all .3s;
}