@use '../../App.scss' as *;

.GFGFlash {
	text-align: center;
	margin: auto;
	width: 50rem;
	/* justify-content: center; */
}

.geeks {
	color: green;
}

.container {
	display: flex;
	justify-content: center;
	margin: auto;
}

.flip-card {
	perspective: 1000px;
	width: 200px;
	height: 300px;
	position: relative;
	transform-style: preserve-3d;
	transition: transform 0.5s;
}

.flip-card.flipped {
	transform: rotateY(180deg);
}

.flip-card-inner {
	width: 100%;
	height: 100%;
	transform-style: preserve-3d;
}

.flip-card-front,
.flip-card-back {
	width: 100%;
	height: 100%;
	position: absolute;
	backface-visibility: hidden;
}

.flip-card-front {
	background-color: #d7fbda;
	color: green;
}

.flip-card-back {
	background-color: #fbd7f8;
	color: blue;
	transform: rotateY(180deg);
}

.card-content {
	padding: 20px;
	text-align: center;
}

.flip-button {
	width: 100px;
	padding: 10px;
	font-size: 16px;
	margin-top: 10px;
	background-color: #f5d9fa;
	border: none;
	border-radius: 5px;
	cursor: pointer;
}
