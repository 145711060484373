@use '../../App.scss' as *;

.card-stack {
    perspective: 1000px;
    width: 300px;
    height: 200px;
    margin: 0 auto;
  }
  
  .card {
    width: 100%;
    height: 100%;
    position: absolute;
    transform-style: preserve-3d;
    // transition: transform 0.5s, z-index 0.5s;
    transition: transform 0.5s;
  }
  
  .card.active {
    z-index: 1;
    transform: translateZ(0);
  }
  
  .card:not(.active) {
    z-index: 0;
    transform: translateZ(-50px); /* Adjust the depth of the stack */
  }
  
  .card-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .card-content.back {
    transform: rotateX(180deg) translateZ(50px); /* Adjust the depth of the stack */
  }
  
  .question,
  .answer {
    font-size: 18px;
  }
  
  /* Add more styles as needed */
  