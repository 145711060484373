@use '../../App.scss' as *;
@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800');

.nav-text {
    color: $textGray !important;
}

.nav-text:hover {
    color: $textSilver !important;
}
