@use '../../App.scss' as *;

#quizContent {
    width: 80%;
    margin: auto;
    margin-top: 20px;
    background-color: $purpleBase;
    color: $textWhite;  
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 2%;
    border-radius: 15px;
    transition: all .3s;
}

#aArea {
  text-align:left;
  padding-left:2%;
}

.dropdown {
  position: relative;
}

.menu {
  position: absolute;
  list-style-type: none;
  
  margin: 5px 0;
  padding: 0;

  border: 1px solid grey;
  width: 300px;
}

.menu > li {
  margin: 0;
  background-color: white;
}

.menu > li:hover {
  background-color: lightgray;
}

.menu > li > button {
  width: 100%;
  height: 100%;
  text-align: left;

  background: none;
  // color: inherit;
  color: $plumPurple;
  border: none;
  padding: 5px;
  margin: 0;
  font: inherit;
  cursor: pointer;
}
