@use '../../App.scss' as *;

.flippable-card-container {
    perspective: 1000px;
  }
  
  .flippable-card {
    width: 200px;
    height: 300px;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.6s;
  }

// .flipped {
//     transform: rotateY(180deg);
// }
  
//   .flippable-card:hover {
//     transform: rotateY(180deg);
//   }
  
  .card-face {
    position: absolute;
    width: 100%;
    height: 100%;
    // backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  .front,
  .back {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  
  .front {
    background-color: #ffffff;
  }
  
  .back {
    background-color: #f0f0f0;
    transform: rotateY(180deg);
  }
  
  .hidden {
    display: none;
  }
  