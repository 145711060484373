#infoRow {
    font-size: 1.4em;
    padding: 1%;
  }
  #svgRow {
    padding: 1%;
  }
  #answerForm {
    padding: 3%;
  }
  #submitBtn {
    margin: 4%;
  }
  
  #circleField {
    width: inherit;
    height: inherit;
    align-self: center;
    padding: 1%;
  }